<ng-template #loading>
  <div class="actionContent">
    <div pyxisBusySpinner></div>
  </div>
</ng-template>
<ng-template #error>
  <div>
    <pyxis-communication-error></pyxis-communication-error>
  </div>
</ng-template>
<!--Main Content-->
<div id="container">
  <h1>Redirecting this page to your document...</h1>
  <h2>If your document does not load in 15 seconds, please try again</h2>
  <ng-container *ngIf="!loading; else loading">
    <ng-container *ngIf="!error; else error">
    </ng-container>
  </ng-container>
</div>



import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {Theme} from './theme.enum';
import {MtlUtilFunctions} from 'mtl-core-lib';
import { ThemesServiceProxy } from './themes-service-proxy/themes-service-proxy.service';

@Injectable()
export class ThemeToggleService {
  private readonly _renderer: Renderer2;

  private _selectedTheme: Theme;
  public get selectedTheme(): Theme {
      return this._selectedTheme;
  }

  constructor(
    rendererFactory: RendererFactory2,
    private themeServiceProxy: ThemesServiceProxy) {
    this._renderer = rendererFactory.createRenderer(null, null);
    const sessionStorageValue = localStorage.getItem('theme');
    this._selectedTheme = MtlUtilFunctions.isNullOrUndefined(sessionStorageValue) ? Theme.Dark : Theme[sessionStorageValue];
  }

  toggleTheme() {
    this._selectedTheme = this._selectedTheme === Theme.Light ? Theme.Dark : Theme.Light;
    localStorage.setItem('theme', Theme[this._selectedTheme]);
    this.initialiseTheme();
  }

  initialiseTheme() {
    if(this._selectedTheme === Theme.Light) {
      this._renderer.removeClass(document.body, 'dark-theme');
      this.themeServiceProxy.setCurrentTheme('generic.light');
    } else {
      this._renderer.addClass(document.body, 'dark-theme');
      this.themeServiceProxy.setCurrentTheme('generic.dark');
    }
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'pyxis-document-downloading-error',
  templateUrl: './document-downloading-error.component.html',
  styleUrls: ['./document-downloading-error.component.scss']
})
export class DocumentDownloadingErrorComponent{

}

import { Component } from '@angular/core';

@Component({
  selector: 'pyxis-document-downloading',
  templateUrl: './document-downloading.component.html',
  styleUrls: ['./document-downloading.component.scss']
})
export class DocumentDownloadingComponent {
  private _loading = true;
  public get loading(): boolean {
    return this._loading;
  }

  private _error = false;
  public get error(): boolean {
    return this._error;
  }

}

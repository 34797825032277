<div class="app-home-wrapper background-image">
  <div class="grid-container">
    <div class="frosted-pane">
      <div class="grid-container_top">
        <img class="grid-container_img" src="assets/logos/PyxisLogo.png" alt="PYXIS Logo">
        <h4 class="grid-container_title">Integrity Management</h4>
      </div>
      <section class="app-error-content-container-grid">
        <h1>Support</h1>
        <p>Please contact <a href="mailto:pyxishelp@technical-limits.com" class="home-link" target="_blank" rel="noopener noreferrer">Marine Technical Limits</a> for more support <br>or call us on
          <a href="tel:01467 424000" target="_blank" rel="noopener noreferrer" class="home-link">01467 424000</a> (Mon-Fri 08:00 - 17:00) or
          <a href="tel:07989 540074" target="_blank" rel="noopener noreferrer" class="home-link">07989 540074</a> (out of hours)
        </p>
        <a routerLink="/" class="button-info-central error-btn">Home</a>
        <div class="border-bottom"></div>
      </section>
      <pyxis-footer-social class="social-grid"></pyxis-footer-social>
    </div>
  </div>
</div>

<div class="app-home-wrapper background-image">
  <div class="grid-container">
    <div class="frosted-pane">
    <div class="grid-container_top">
      <img class="grid-container_img" src="assets/logos/PyxisLogo.png" alt="PYXIS Logo">
      <h4 class="grid-container_title">Integrity Management</h4>
    </div>
    <section class="app-not-found-content-container-grid">
      <h1>404</h1>
      <h4>Page cannot be found</h4>
      <h4>The page you are looking for might have been removed or doesn't exist.</h4>
      <a routerLink="/" class="button-info-central error-btn">Return Home</a>
      <div class="border-bottom"></div>
    </section>
    <section class="app-not-found-content-container-grid_bottom">
      <pyxis-footer-social class="social-grid"></pyxis-footer-social>
    </section>
    </div>
  </div>
</div>

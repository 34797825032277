<div class="app-home-wrapper background-image error-template">
  <div class="grid-container">
    <div class="frosted-pane">
      <div class="grid-container_top">
        <img class="grid-container_img" src="assets/logos/PyxisLogo.png" alt="PYXIS Logo">
        <h4 class="grid-container_title">Integrity Management</h4>
      </div>
      <section class="report-request-error-content-container-grid">
        <img src="/assets/icons/warning-icon.svg" alt="warning" width="100px" style="margin: 0 auto 20px">
        <h2>Error requesting a report.</h2>
        <p>Please contact <a href="http://www.technical-limits.com#contact" class="home-link">Marine Technical Limits</a> for assistance.</p>

        <div class="border-bottom"></div>
      </section>
      <section class="app-not-found-content-container-grid_bottom">
        <pyxis-footer-social class="social-grid"></pyxis-footer-social>
      </section>
    </div>
  </div>
</div>

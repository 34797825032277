<div class="footer-container-grid">
  <div class="grid-inner">
    <a href="tel:01224 208180" target="_blank" rel="noopener noreferrer"><div class="phone-icon"></div></a>
    <a href="http://www.technical-limits.com#contact" target="_blank" rel="noopener noreferrer"><div class="email-icon"></div></a>
    <a href="https://www.linkedin.com/company/marine-technical-limits-ltd" target="_blank" rel="noopener noreferrer"><div class="linkedin-icon"></div></a>
    <a href="https://www.youtube.com/channel/UC4ZUrbSnJmScB7Y0ZsoaXAw" target="_blank" rel="noopener noreferrer"><div class="youtube-icon"></div></a>
  </div>
  <div class="grid-inner" style="padding-bottom: 0">
    <a href="https://www.agcc.co.uk/" target="_blank" rel="noopener noreferrer"><div class="accred_one"></div></a>
    <a href="https://www.rina.org.uk/Marine_Technical_Limits.html" target="_blank" rel="noopener noreferrer"><div class="accred_two"></div></a>
    <a href="https://oeuk.org.uk/" target="_blank" rel="noopener noreferrer"><div class="accred_three"></div></a>
  </div>
</div>


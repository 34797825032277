import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pyxis-app-session-error',
  templateUrl: './app-session-error.component.html',
  styleUrls: ['../shared/scss/app-error.component.scss']
})
export class AppSessionErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import themes from 'devextreme/ui/themes';

@Injectable({
  providedIn: 'root'
})
export class ThemesServiceProxy {

  setCurrentTheme(theme: string) { themes.current(theme); }
}

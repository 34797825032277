import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppHomeComponent } from './components/app-home/app-home.component';
import { AppNotFoundComponent } from './components/app-not-found/app-not-found.component';
import { AppErrorComponent } from './components/app-error/app-error.component';
import { MsalGuard } from '@azure/msal-angular';
import {ReportLoaderComponent} from 'pyxis-core-lib';
import {AppSessionErrorComponent} from './components/app-session-error/app-session-error.component';
import {AppPermissionErrorComponent} from './components/app-permission-error/app-permission-error.component';
import {DocumentDownloadingComponent} from './components/document-downloading/document-downloading.component';
import {
  DocumentDownloadingErrorComponent
} from './components/document-downloading-error/document-downloading-error.component';
import {AppSupportComponent} from './components/app-support/app-support.component';

const app_routes: Routes = [
  {
    path: 'launch',
    loadChildren: () => import('pyxis-core-lib').then(mod => mod.LaunchModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'account/report',
    component: ReportLoaderComponent
  },
  {
    path: 'documentDownloading',
    component: DocumentDownloadingComponent
  },
  {
    path: 'documentDownloadError',
    component: DocumentDownloadingErrorComponent
  },
  {
    path: 'support',
    component: AppSupportComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(mod => mod.AccountModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'configuration',
    loadChildren: () => import('./modules/configuration/configuration.module').then(mod => mod.ConfigurationModule)
  },
  { path: 'error', component: AppErrorComponent },
  { path: 'session-error', component: AppSessionErrorComponent},
  { path: 'permission-error', component: AppPermissionErrorComponent},
  { path: '', component: AppHomeComponent },
  { path: '**', component: AppNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(app_routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

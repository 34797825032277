<div class="app-home-wrapper background-image">
  <div class="grid-container">
    <div class="frosted-pane">
      <div class="grid-container_top">
        <img class="grid-container_img" src="assets/logos/PyxisLogo.png" alt="PYXIS Logo">
        <h4 class="grid-container_title">Integrity Management</h4>
      </div>
      <section class="app-error-content-container-grid">
        <h1>An error has occurred!</h1>
        <h4>Please contact <a href="http://www.technical-limits.com#contact" class="home-link">Marine Technical Limits</a> if this error persists.</h4>
        <a routerLink="/" class="button-info-central error-btn">Home</a>
        <div class="border-bottom"></div>
      </section>
      <pyxis-footer-social class="social-grid"></pyxis-footer-social>
    </div>
  </div>
</div>

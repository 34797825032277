<div class="app-home-wrapper background-image">
  <div class="grid-container">
    <div class="frosted-pane">
      <div class="grid-container_top">
        <img class="grid-container_img" src="assets/logos/PyxisLogo.png" alt="Pyxis Logo">
        <h4 class="grid-container_title">Integrity Management</h4>
      </div>

      <section class="app-home-content-container-grid">
        <section class="app-home-content-container-login">
          <a routerLink="/launch/" class="button-info-central" style="margin-top: 15px;">Log In</a>
        </section>
        <section class="register">
          <a href="http://www.technical-limits.com" class="home-link">&copy; Marine Technical Limits {{currentYear}}</a>
        </section>
        <pyxis-footer-social class="social-grid"></pyxis-footer-social>
      </section>
    </div>
  </div>
</div>

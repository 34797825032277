import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {PYXIS_SESSION_ID} from '../constants/storage.constants';
import {AUTH_SESSION_ID} from '../constants/authentication.constants';
import {Router} from '@angular/router';
import {MtlUtilFunctions} from 'mtl-core-lib';
import {LocalStorageService} from 'pyxis-core-lib';

@Injectable()
export class PyxisSessionInterceptor implements HttpInterceptor {

  constructor(private readonly _router: Router, private readonly _localStorageService: LocalStorageService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const localStorageSessionId = localStorage.getItem(PYXIS_SESSION_ID);
    const serviceSessionId = this._localStorageService.sessionId;

    if (!MtlUtilFunctions.isNullOrUndefined(serviceSessionId) && serviceSessionId !== localStorageSessionId){
      this._router.navigate(['/session-error']).then();
      return;
    }

    if (!MtlUtilFunctions.isNullOrUndefined(localStorageSessionId)){
      request = request.clone({headers: request.headers.set(AUTH_SESSION_ID, localStorageSessionId)});
    }

    return next.handle(request);
  }
}

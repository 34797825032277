import { Component } from '@angular/core';

@Component({
  selector: 'pyxis-footer-social',
  templateUrl: './footer-social.component.html',
  styleUrls: ['./footer-social.component.scss']
})
export class FooterSocialComponent {

}

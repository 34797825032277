import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home',
  templateUrl: './app-home.component.html',
  styleUrls: ['./app-home.component.scss']
})
export class AppHomeComponent implements OnInit {

  currentYear: number = new Date().getFullYear();
  constructor() {
   }

  ngOnInit() {
  }
}

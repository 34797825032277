import {PyxisEnvironment} from 'pyxis-core-lib';
import { Injectable } from '@angular/core';
import { companyLogo, environment } from '../../environments/environment';

@Injectable()
export class AppPyxisEnvironment extends PyxisEnvironment {
  constructor() {
    super({apiUrl: environment.apiUrl, production: environment.production, redirectUri: environment.redirectUri},
      {logoImg: companyLogo.logoImg});
  }
}

import { Component, OnInit } from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {Router} from '@angular/router';

@Component({
  selector: 'pyxis-app-permission-error',
  templateUrl: './app-permission-error.component.html',
  styleUrls: ['../shared/scss/app-error.component.scss']
})
export class AppPermissionErrorComponent implements OnInit {

  constructor(private authService: MsalService, private router: Router) { }

  ngOnInit(): void {
  }

  logOut() {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

}

import {Component, OnInit} from '@angular/core';
import dxDataGrid from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit {
  title = 'pyxis-online';

  constructor() {
    dxDataGrid.defaultOptions({
      options: {
        columnResizingMode: 'widget'
      }
    });
  }

  ngOnInit(): void {
    const loader = (window as any).UnityLoader;
    loader.Error.handler = (e, t) => {
      console.log(e);
    }
  }
}

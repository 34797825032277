import { BrowserModule } from '@angular/platform-browser';
import {NgModule, ErrorHandler, ComponentFactoryResolver} from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppHomeComponent } from './components/app-home/app-home.component';
import { AppNotFoundComponent } from './components/app-not-found/app-not-found.component';
import { AppErrorComponent } from './components/app-error/app-error.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {MtlCoreLibModule} from 'mtl-core-lib';
import {
  ComponentResolverService,
  ComponentType, LocalStorageService, PyxisCoreModule,
  PyxisEnvironment,
  PyxisErrorHandler
} from 'pyxis-core-lib';
import {AppPyxisEnvironment} from './environment/app-pyxis-environment';
import {ThemeToggleService} from './modules/account/services/theme-toggle.service';
import {PyxisSessionInterceptor} from './infrastructure/interceptors/pyxis-session.interceptor';
import {InteractionType, PublicClientApplication} from '@azure/msal-browser';
import {MsalModule, MsalGuard, MsalInterceptor, MsalRedirectComponent} from '@azure/msal-angular';
import {FindingComponent} from 'pyxis-anomalies-lib';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const interceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: PyxisSessionInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  }
];

const componentResolverServiceFactory = (componentFactoryResolver: ComponentFactoryResolver) => {
  const service = new ComponentResolverService(componentFactoryResolver);
  service.initialise([{type: ComponentType.Finding, component: FindingComponent}]);

  return service;
}
import { ThemesServiceProxy } from './modules/account/services/themes-service-proxy/themes-service-proxy.service';
import { AppSessionErrorComponent } from './components/app-session-error/app-session-error.component';
import {MatSelectModule} from '@angular/material/select';
import { AppPermissionErrorComponent } from './components/app-permission-error/app-permission-error.component';
import {DocumentDownloadingComponent} from './components/document-downloading/document-downloading.component';
import {
  DocumentDownloadingErrorComponent
} from './components/document-downloading-error/document-downloading-error.component';
import { FooterSocialComponent } from './components/shared/component/footer-social.component';
import {ActiveModuleHandler} from './modules/account/handlers/active-module.handler';
import {ReportRequestErrorComponent} from './components/report-request-error/report-request-error.component';
import {AppSupportComponent} from './components/app-support/app-support.component';
import {AngularResizeElementModule} from "angular-resize-element";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from '@ngrx/effects';

function getClientId() {
  switch (location.origin)
  {
    case 'https://ithaca.mtlpyxis.com':
    case 'https://ithaca-beta.mtlpyxis.com':
       return '2b571149-1ec1-4b66-baa4-cbe62e74c62f';
    case 'https://cenovus.mtlpyxis.com':
    case 'https://cenovus-beta.mtlpyxis.com':
       return 'a6775e1a-ffa4-46fa-8241-6b922c0c2da3';
    case 'https://suncor.mtlpyxis.com':
    case 'https://suncor-beta.mtlpyxis.com':
       return 'b10c4932-14f7-4a7b-9aaa-60ff994e3e7e';
    case 'https://total.mtlpyxis.com':
    case 'https://total-beta.mtlpyxis.com':
       return '9414a2d7-8e01-4254-b0e6-9c1199b9ad85';
    case 'https://bluewater.mtlpyxis.com':
    case 'https://bluewater-beta.mtlpyxis.com':
       return 'e890fd34-9ec6-4dd5-8a6e-7a312d11c00a';
    case 'https://repsol.mtlpyxis.com':
    case 'https://repsol-beta.mtlpyxis.com':
       return '0e8c6506-1ea9-4a66-b17b-d5aa70b36aea';
    case 'https://droneq.mtlpyxis.com':
    case 'https://droneq-beta.mtlpyxis.com':
      return '51e7662d-b89d-4c40-8692-ad7711388d7d';
     case 'https://dev.mtlpyxis.com':
     case 'https://dev-ingress.mtlpyxis.com':
       return 'db9d865f-0312-46bd-b96b-0752ddd8bdda';
    case 'https://uat.mtlpyxis.com':
    case 'https://uat-ingress.mtlpyxis.com':
      return '3ac747b5-b409-42a3-a4bb-ab771fa7119a';
    case 'https://neo.mtlpyxis.com':
      return 'e5ac474b-71ed-440c-8fe9-0a0a28cab474';
    case 'https://a-o.mtlpyxis.com':
      return 'af8cacc5-86ec-4db2-898e-a33903a3c8b5';
    default:
      return 'db9d865f-0312-46bd-b96b-0752ddd8bdda';
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AppHomeComponent,
    AppNotFoundComponent,
    AppErrorComponent,
    AppSessionErrorComponent,
    AppPermissionErrorComponent,
    DocumentDownloadingComponent,
    DocumentDownloadingErrorComponent,
    ReportRequestErrorComponent,
    AppSupportComponent,
    FooterSocialComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSnackBarModule,
    MatTooltipModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: getClientId(),
        authority: 'https://login.microsoftonline.com/02a7ffc9-caed-4deb-98af-2ac379e7e56b/',
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
      }
    }), {
      interactionType: InteractionType.Redirect
    }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        ['api/*', ['api://10e11ed9-c7e4-465a-bd58-4373038e3798/AccessAPI']],
        ['https://graph.microsoft.com', ['user.read']]
      ])
    }),
    MatSelectModule,
    MtlCoreLibModule,
    PyxisCoreModule,
    AngularResizeElementModule
  ],
  providers: [
    interceptorProviders,
    MsalGuard,
    {provide: LocalStorageService, useClass: LocalStorageService},
    {provide: PyxisEnvironment, useClass: AppPyxisEnvironment},
    ThemeToggleService,
    ThemesServiceProxy,
    ActiveModuleHandler,
    {provide: ErrorHandler, useExisting: PyxisErrorHandler},
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {
      provide: ComponentResolverService,
      useFactory: componentResolverServiceFactory,
      deps: [ComponentFactoryResolver]
    }
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule {}
